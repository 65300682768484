import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import photo1 from '../images/DIOGO_BANNER.png'; // Importing the image
import photo2 from '../images/KEVIN_BANNER.png'; // Importing the image
import photo3 from '../images/RUBEN_BANNER.png'; // Importing the image
import photo4 from '../images/TOMAS_BANNER.png'; // Importing the image
import photo5 from '../images/VASCO_BANNER.png'; // Importing the image

const photos = [photo1, photo2, photo3, photo4,photo5]; // Create an array of photos


function Caroussel() {  
  return (
    <div className="carousel-container">
      <Carousel           // Adds the fade transition
        controls={false} // Hides the left and right controls
        indicators={false} // Hides the dot indicators
        interval={2000} // Auto slide every 3 seconds
        pause={false} // Prevent pausing on hover
        infinite={true} // Enables infinite looping
      >
                {photos.map((photo, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={photo}
              alt={`Slide ${index + 1}`}
              loading="lazy"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Caroussel;
