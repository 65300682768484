import React from 'react';
import '../Styles/funcionalidades.css';

const Funcionalidades = () => {
  return (
    <div className='funcionalidades-style'>
      <h1 className='main-title'>FUNCIONALIDADES</h1>
      <p className='main-text'>
        O Skyblaze é uma plataforma inovadora que utiliza inteligência artificial para identificar zonas de risco de incêndio a partir de imagens e vídeos em vista aérea (birds-eye view). Com uma interface simples e intuitiva, o Skyblaze oferece uma solução acessível e eficiente para a prevenção de incêndios. Aqui estão as principais funcionalidades:
      </p>
      <ul className='main-text'>
        <li><strong>1. Upload de Imagens:</strong> Permite aos utilizadores carregar facilmente imagens capturadas por drones, satélites ou câmaras. A plataforma suporta diversos formatos de ficheiros, garantindo máxima compatibilidade e acessibilidade.</li>
        <li><strong>2. Análise Automatizada de Risco:</strong> Através de algoritmos avançados de inteligência artificial, o Skyblaze analisa as imagens e vídeos carregados, identificando zonas de vegetação densa, materiais inflamáveis e outros fatores que representam um risco elevado de incêndio.</li>
        <li><strong>3. Relatórios Detalhados:</strong> Os utilizadores recebem relatórios personalizados com mapas e gráficos que destacam as áreas de maior risco, permitindo uma análise clara e detalhada para a tomada de decisões preventivas.</li>
        <li><strong>4. Alertas e Recomendações:</strong> Com base na análise, a plataforma gera alertas sobre as zonas críticas e oferece recomendações práticas para mitigar os riscos identificados. Estas informações podem ser partilhadas com proprietários, seguradoras ou autoridades locais.</li>
        <li><strong>5. Histórico de Análises:</strong> Guarda os resultados das análises anteriores, permitindo que os utilizadores acompanhem a evolução dos riscos ao longo do tempo e comparem mudanças em áreas específicas.</li>
        <li><strong>6. Flexibilidade de Utilização:</strong> O Skyblaze é adequado para diferentes utilizadores, incluindo:
          <ul>
            <li>Proprietários de terrenos e florestas: Identificar riscos e proteger as suas propriedades.</li>
            <li>Seguradoras: Monitorizar as propriedades dos clientes e reduzir custos com danos.</li>
            <li>Entidades governamentais: Prevenir incêndios florestais em áreas sensíveis.</li>
          </ul>
        </li>
        <li><strong>7. Interface Intuitiva e Acessível:</strong> A plataforma foi desenvolvida para ser simples de usar, mesmo para quem não tem experiência técnica. O processo de upload e análise é rápido e eficaz, garantindo que qualquer utilizador possa beneficiar da tecnologia.</li>
        <li><strong>8. Atualização em Tempo Real:</strong> A análise é realizada em tempo real, permitindo ações preventivas imediatas, ao contrário de outras soluções que dependem de satélites e podem apresentar atrasos.</li>
      </ul>
      <p className='main-text'>
        O Skyblaze é a ferramenta ideal para quem procura uma abordagem proativa, tecnológica e acessível para proteger florestas, comunidades e propriedades contra o impacto devastador dos incêndios.
      </p>
    </div>
  );
};

export default Funcionalidades;